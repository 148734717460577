import { logToSentry } from '@otovo/shared/lib/sentry';
import throwForFalsy from '@otovo/shared/lib/throwForFalsy';
import { Cloud$OAuthToken } from '@otovo/shared/types/cloudApi';

export const ERROR_CODE = {
  INVALID_CODE: 'Invalid code',
  INVALID_EMAIL: 'Invalid email',
  TOO_MANY_ATTEMPTS: 'Too many attempts',
};

type GenerateOTPResponseShape = {
  medium: 'sms' | 'email';
};

export async function generateOtpCode(
  email: string,
): Promise<GenerateOTPResponseShape> {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: email,
    }),
  };
  const resp = await fetch(
    `${throwForFalsy(
      process.env.NEXT_PUBLIC_OTOVOAPI_PUBLIC_URL,
    )}/otp/generate-otp/`,
    options,
  );
  const json = await resp.json();

  if (!json || resp.status >= 500) {
    logToSentry('5xx response from server while generating OTP', {
      status: resp.status,
      data: json,
    });
    throw new Error(json);
  }

  if (resp.status >= 400) {
    if (resp.status === 429) {
      throw new Error(ERROR_CODE.TOO_MANY_ATTEMPTS);
    }
    throw new Error(ERROR_CODE.INVALID_EMAIL);
  }

  return {
    medium: json.medium,
  };
}

export async function loginUsingOTP(email: string, code: string) {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code,
      user: email,
      client_id: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID,
    }),
  };

  const resp = await fetch(
    `${throwForFalsy(process.env.NEXT_PUBLIC_OTOVOAPI_PUBLIC_URL)}/otp/login/`,
    options,
  );
  const json = await resp.json();

  if (resp.status >= 500) {
    logToSentry('5xx response from server during OTP login', {
      status: resp.status,
      data: json,
    });
    throw new Error(json);
  }
  if (resp.status >= 400) {
    throw new Error(ERROR_CODE.INVALID_CODE);
  }

  const token = {
    ...json,
    received: new Date(),
  } as Cloud$OAuthToken;
  return token;
}
