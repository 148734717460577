import { useTheme } from '@emotion/react';
import { Box } from '@otovo/rainbow';

type Props = {
  size?: string;
  bg?: string;
  color?: string;
};

const ErrorCircle = ({
  size = '5',
  bg = 'red_20',
  color = 'red_100',
}: Props) => {
  const { colors, sizes } = useTheme();
  return (
    <Box
      width={size}
      height={size}
      fontSize={sizes[size] || size}
      color={color}
    >
      <svg
        height="1em"
        width="1em"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <circle cx="10" cy="10" fill={colors[bg] || bg} r="10" />
          <path
            d="m10 10 3-3-3 3-3-3zm0 0-3 3 3-3 3 3z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </Box>
  );
};

export default ErrorCircle;
