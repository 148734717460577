import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'OTPLoginPage';

export default defineMessages({
  myPageTag: {
    id: `${I18N_NAMESPACE}.myPageTag`,
    defaultMessage: 'My page',
  },
  enterOtpHeading: {
    id: `${I18N_NAMESPACE}.enterOtpHeading`,
    defaultMessage: 'Enter one-time password',
  },
  signInToAccountHeading: {
    id: `${I18N_NAMESPACE}.signInToAccountHeading`,
    defaultMessage: 'Sign in to your account',
  },
  signInInstructions: {
    id: `${I18N_NAMESPACE}.signInInstructions`,
    defaultMessage:
      'Enter your registered email address, and we will send you a 6-digit verification code.',
  },
  enterOtpInstructions: {
    id: `${I18N_NAMESPACE}.enterOtpInstructions`,
    defaultMessage:
      'Enter the code you received via <strong>{medium}</strong>. Did not receive a code? <link>Send again.</link>',
  },
  sendCode: {
    id: `${I18N_NAMESPACE}.sendCode`,
    defaultMessage: 'Send code',
  },
  wordContinue: {
    id: `${I18N_NAMESPACE}.wordContinue`,
    defaultMessage: 'Continue',
  },
  invalidEmail: {
    id: `${I18N_NAMESPACE}.invalidEmail`,
    defaultMessage: 'Invalid email',
  },
  networkError: {
    id: `${I18N_NAMESPACE}.networkError`,
    defaultMessage: 'Network error',
  },
  tooManyAttempts: {
    id: `${I18N_NAMESPACE}.tooManyAttempts`,
    defaultMessage: 'Too many attempts. Try again in one hour.',
  },
  invalidOtpCode: {
    id: `${I18N_NAMESPACE}.invalidOtpCode`,
    defaultMessage: 'Not a valid code',
  },
  errorTitle: {
    id: `${I18N_NAMESPACE}.errorTitle`,
    defaultMessage: 'Login failed',
  },
  useOldLogin: {
    id: `${I18N_NAMESPACE}.useOldLogin`,
    defaultMessage: '<link>Log in with email and password?</link>',
  },
});
