import { BodyM, H6 } from '@otovo/rainbow';
import { FormattedMessage as T } from 'react-intl';

import Alert from '@otovo/shared/components/Alert/Alert';
import { ReactElement } from 'react';
import m from './messages';

type Props = {
  title?: string | ReactElement;
  message?: string | ReactElement;
};

const LoginError = ({ title, message }: Props) => {
  return (
    <Alert variant="error">
      <H6 as="h2" mb="3">
        {title || <T {...m.errorTitle} />}
      </H6>
      <BodyM>{message || <T {...m.errorMessageUserProfile} />}</BodyM>
    </Alert>
  );
};

export default LoginError;
