import { useEffect, useState } from 'react';

/**
 * useElementHeight
 * calculates the height of the element when mounting the component
 * @returns a number indicating the height of the element
 */

export function useElementHeightById(elementId) {
  const [elementHeight, setElementHeight] = useState<number>(64);

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      const { height } = element.getClientRects()[0];
      setElementHeight(height);
    } else {
      // No element available
      setElementHeight(0);
    }
  }, [elementId]);

  return elementHeight;
}
