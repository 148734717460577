import { Theme, useTheme } from '@emotion/react';
import { Box } from '@otovo/rainbow';
import hexToRGB from '../../lib/hexToRGB';
import { Rainbow$Props } from '../../types/rainbow';
import InfoCircle from '../Icons/InfoCircle/InfoCircle';
import ErrorCircle from '../Icons/ErrorCircle/ErrorCircle';

type Variant = 'info' | 'error' | 'success';

type AlertProps = Rainbow$Props & {
  children: React.ReactNode;
  variant: Variant;
  title?: string;
};

function getStyles(variant: Variant, theme: Theme) {
  return {
    success: {
      bg: hexToRGB(theme.alias.states.success, 0.1),
      icon: <InfoCircle bg="text-green-40" color="text-white" />,
    },
    info: {
      bg: theme.alias.background_100,
      icon: <InfoCircle />,
    },
    error: {
      bg: 'red_5',
      icon: <ErrorCircle />,
    },
  }[variant];
}

const Alert = ({ children, variant = 'info', ...rest }: AlertProps) => {
  const theme = useTheme();
  const styles = getStyles(variant, theme);

  const { bg, icon } = styles;
  return (
    <Box
      textAlign="left"
      bg={bg}
      p="6"
      borderRadius="3"
      display="flex"
      {...rest}
    >
      {icon}
      <Box ml="3">{children}</Box>
    </Box>
  );
};

export default Alert;
