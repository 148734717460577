import { Box, Button, ErrorMessage, Form } from '@otovo/rainbow';
import { SyntheticEvent, useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import PinField from 'react-pin-field';

import { Cloud$OAuthToken } from '@otovo/shared/types/cloudApi';
import { ERROR_CODE, loginUsingOTP } from './api';
import m from './messages';
import styles from './pinfield.module.scss';

type Props = {
  email: string;
  inputRef: any;
  onTokenReceived: (token: Cloud$OAuthToken) => void;
};

const OTP_LENGTH = 6;
const otpValidationRegex = new RegExp(/[0-9a-zA-Z]/);

const EnterOtpForm = ({ email, inputRef, onTokenReceived }: Props) => {
  const intl = useIntl();
  const [otpCode, setOtpCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const login = async (code: string) => {
    if (code?.length !== OTP_LENGTH) {
      setError(intl.formatMessage(m.invalidOtpCode));
      return;
    }
    setLoading(true);
    try {
      const token = await loginUsingOTP(email, code);
      onTokenReceived(token);
    } catch (err) {
      setLoading(false);
      if (err.message === ERROR_CODE.INVALID_CODE) {
        setError(intl.formatMessage(m.invalidOtpCode));
      } else {
        setError(intl.formatMessage(m.networkError));
      }
    }
  };

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    login(otpCode);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box display="inline-flex" maxWidth="100%" position="relative">
        <PinField
          ref={inputRef}
          autoComplete="one-time-code"
          className={[
            styles['pin-field'],
            otpCode?.length === OTP_LENGTH && styles.complete,
            error && styles.error,
          ].join(' ')}
          length={OTP_LENGTH}
          style={{
            flex: '0 0 auto',
            minHeight: 0,
            minWidth: 0,
          }}
          validate={otpValidationRegex}
          format={(char) => char.toUpperCase()}
          onComplete={login}
          onChange={(otp) => {
            setError('');
            setOtpCode(otp);
          }}
        />
      </Box>
      {!!error && (
        <ErrorMessage
          mt="2"
          backgroundColor={{ s: 'red_5', m: 'transparent' }}
          py="1"
          px="1"
          borderRadius="2"
        >
          {error}
        </ErrorMessage>
      )}

      <Box mt="6">
        <Button loading={loading} type="submit">
          <T {...m.wordContinue} />
        </Button>
      </Box>
    </Form>
  );
};

export default EnterOtpForm;
