import { Box } from '@otovo/rainbow';
import { useElementHeightById } from '@otovo/shared/hooks/useElementHeightById';
import { useIntl } from 'react-intl';

import Head from '@otovo/shared/components/Head/Head';
import smartCity from '@otovo/shared/images/photos/backgrounds/smart-city.jpg';
import m from './messages';

type Props = {
  children: React.ReactNode;
};

const PageWrapper = ({ children }: Props) => {
  const intl = useIntl();
  const navbarHeight = useElementHeightById('otovoNavBar');

  return (
    <div>
      <Box
        display="flex"
        style={{
          minHeight: `calc(100vh - ${navbarHeight}px)`,
        }}
        position="relative"
        textAlign={{ s: 'center', m: 'left' }}
        flexDirection={{ s: 'row', m: 'row-reverse' }}
      >
        <Box
          flexGrow="1"
          flexShrink="1"
          flexBasis="0%"
          position={{ s: 'absolute', m: 'relative' }}
          top="0"
          bottom="0"
          left="0"
          right="0"
          width="100%"
        >
          <Box
            as="img"
            src={smartCity}
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            width="100%"
            height="100%"
            backgroundPosition
            objectFit="cover"
          />
        </Box>
        <Head
          title={intl.formatMessage(m.metaTitle)}
          description={intl.formatMessage(m.metaDescription)}
        />
        <Box
          width="100%"
          maxWidth="14"
          py={{ s: 4, m2: 7 }}
          px={{ s: 7, m2: 9 }}
          display="flex"
          flexDirection="column"
          position="relative"
          justifyContent={{ s: 'flex-start', m: 'center' }}
          mx="auto"
        >
          <Box>{children}</Box>
        </Box>
      </Box>
    </div>
  );
};

export default PageWrapper;
