import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'LoginPage';

export default defineMessages({
  metaTitle: {
    id: `${I18N_NAMESPACE}.metaTitle`,
    defaultMessage: 'Log in',
  },

  metaDescription: {
    id: `${I18N_NAMESPACE}.metaDescription`,
    defaultMessage: 'Log into otovo.no to get access to My page.',
  },

  title: {
    id: `${I18N_NAMESPACE}.title`,
    defaultMessage: 'Log in',
  },

  newUserLabel: {
    id: `${I18N_NAMESPACE}.newUserLabel`,
    defaultMessage: 'New user?',
  },

  forgottenPasswordTitle: {
    id: `${I18N_NAMESPACE}.forgottenPasswordTitle`,
    defaultMessage: 'Forgot your password?',
  },

  forgottenPasswordDescription: {
    id: `${I18N_NAMESPACE}.forgottenPasswordDescription`,
    defaultMessage: 'Click here to receive a new password',
  },

  loginButton: {
    id: `${I18N_NAMESPACE}.loginButton`,
    defaultMessage: 'Log in',
  },

  passwordInputLabel: {
    id: `${I18N_NAMESPACE}.passwordInputLabel`,
    defaultMessage: 'Password',
  },

  errorTitle: {
    id: `${I18N_NAMESPACE}.errorTitle`,
    defaultMessage: 'Login failed',
  },

  errorMessageUnauthorized: {
    id: `${I18N_NAMESPACE}.errorMessageUnauthorized`,
    defaultMessage: 'Incorrect user name or password',
  },

  errorMessageUserProfile: {
    id: `${I18N_NAMESPACE}.errorMessageUserProfile`,
    defaultMessage:
      'Could not load the user profile. Log in again or contact us for help',
  },
});
