type Props = {
  size?: string;
  bg?: string;
  color?: string;
};

const InfoCircle = ({
  size = '6',
  bg = 'text-lilac-10', // text color sets the bg color via fill='currentColor'
  color = 'text-blue-60',
}: Props) => {
  return (
    <div className={`size-${size}`}>
      <svg
        viewBox="0 0 24 24"
        className={`size-${size} ${color}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path
            className={bg}
            fill="currentColor"
            d="m12 24c-6.627417 0-12-5.3725831-12-12 0-6.627417 5.372583-12 12-12 6.6274169 0 12 5.372583 12 12 0 6.6274169-5.3725831 12-12 12z"
          />
          <g fill="currentColor" fillRule="nonzero">
            <path d="m13.5306042 16.4590627c0 .8453293-.6852749 1.5306042-1.5306042 1.5306042s-1.5306042-.6852749-1.5306042-1.5306042v-4.1224167c0-.8453293.6852749-1.5306042 1.5306042-1.5306042s1.5306042.6852749 1.5306042 1.5306042z" />
            <path d="m13.5306042 7.93565217c0 .75909947-.7067299 1.32448335-1.5306042 1.32448335s-1.5306042-.56538388-1.5306042-1.32448335v-.41224167c0-.75909946.7067299-1.32448334 1.5306042-1.32448334s1.5306042.56538388 1.5306042 1.32448334z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default InfoCircle;
